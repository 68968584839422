.footer-dark {
    text-align: start;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 55px;
}

.footer-dark {
    padding: 40px 0;
    color: #f0f9ff;
    background-color: #282d32;
}

.footer-dark h3 {
    margin-top: 0;
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 16px;
}

.footer-dark ul {
    padding: 0;
    list-style: none;
    line-height: 1.6;
    font-size: 14px;
    margin-bottom: 0;
}

.footer-dark ul a {
    color: inherit;
    text-decoration: none;
    opacity: 0.6;
}

.footer-dark ul a:hover {
    opacity: 0.8;
}

@media (max-width:767px) {
    .footer-dark:not(.social) {
        text-align: center;
        padding-bottom: 20px;
    }
}

.footer-dark .text {
    margin-bottom: 36px;
}

@media (max-width:767px) {
    .footer-dark .text {
        margin-bottom: 0;
    }
}

.footer-dark .text p {
    opacity: 0.6;
    margin-bottom: 0;
}

.footer-dark .social {
    text-align: center;
}

@media (max-width:991px) {
    .footer-dark .social {
        text-align: center;
        margin-top: 20px;
    }
}

.footer-dark .social>span {
    font-size: 20px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4);
    margin: 0 8px;
    color: #fff;
    opacity: 0.75;
}

.footer-dark .social>span:hover {
    opacity: 0.9;
    cursor: pointer;
}

.footer-dark .copyright {
    text-align: center;
    padding-top: 24px;
    opacity: 0.3;
    font-size: 13px;
    margin-bottom: 0;
    color: yellow;
}

.terms a {
    border-bottom: 1px solid #fff;
}

.about span {
    color: #fff;
    border-bottom: 1px solid #fff;
    cursor: pointer;
    opacity: 0.6;
}

.about span:hover {
    opacity: 0.8;
}