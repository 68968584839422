.MyCourses__component {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
}

.modal-90w {
    width: 90% !important;
    min-width: 90% !important;
}



.react-quill img {
    max-width: 400px;
    max-height: 400px;
}

@media only screen and (max-width: 600px) {
    .react-quill img {
        max-width: 300px;
        max-height: 400px;
    }
}