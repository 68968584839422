.students_courses__main_div {
    margin: 10px !important;
    padding: 20px !important;
}

.students_courses__top_div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}




@media (max-width: 990px) {
    .students_courses__top_div {
        flex-direction: column;
        margin-bottom: 10px;
    }
}