.sidebar__icon:active{
    background-color: #ffcc00;
}

.sidebar__navbar{
    margin-left: 20px;
}


.sidebar__ul{
    display: flex;
    flex-direction: row;
    margin-top: 17px;
}

.sidebar__ul li{
    list-style: none;
    margin: 4px;
    font-size: 13px;
    font-weight: bold;
    padding: 7px;
    border-left: 2px solid black;
    border-bottom: 2px solid black;
    border-radius: 5px;
    background-color: white;
    color: black;
}

.sidebar__ul li:hover{
    background-color: #ffcc00;
    color: black;
    border-radius: 5px;
    cursor: pointer;
}