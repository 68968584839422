.checout__course{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.checkout__course_image{
    width: 70px;
    height: 70px;
    border-radius: 10px;
    margin-right: 3px;
}