.BlogDetails{
    text-align: start;
}
.BlogDetails img{
    max-width: 400px;
    max-height: 400px;
}

@media only screen and (max-width: 600px) {
    .BlogDetails img{
        max-width: 300px;
        max-height: 400px;
    }
  }