.played_video_div {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.played_video_div iframe {
    padding: 2px;
    width: 100%;
    height: 70vh;
}



@media screen and (max-width: 800px) {
    .played_video_div iframe {
        padding: 2px;
        width: 100%;
        height: 40vh;
    }
}