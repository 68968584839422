.MyCourses__component {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
}

.c-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.c-card {
    box-sizing: border-box;
    width: 250px;
    background: #fff;
    margin: auto;
    margin: 10px;
    border: 1px solid rgb(250, 215, 215);
    border-radius: 3px;
}

.c-card-image {
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 190px;
    padding: 16px;
}

.c-card-content {
    clear: both;
    box-sizing: border-box;
    padding: 16px;
    background: #fff;
}

.fpo {
    position: relative;
    margin: 25% auto;
    display: block;
}

.pulsate {
    background: linear-gradient(-45deg, #DDDDDD, #F0F0F0, #DDDDDD, #F0F0F0);
    background-size: 400% 400%;
    -webkit-animation: Gradient 2.25s ease infinite;
    -moz-animation: Gradient 2.25s ease infinite;
    animation: Gradient 2.25s ease infinite;
}

.block {
    display: block;
    width: 271px;
    height: 16px;
    color: black;
}

.block-course-heading {
    width: 100%;
    height: 25px;
    margin-bottom: 12px;
    border-radius: 5px;
}

.block-user {
    width: 100px;
    height: 15px;
    margin-bottom: 16px;
    border-radius: 2px;
}

.block-sub-heading {
    width: 100%;
    height: 25px;
    margin-bottom: 16px;
    border-radius: 5px;
}

.block-stars {
    width: 100px;
    height: 20px;
    margin-bottom: 16px;
    border-radius: 2px;
}

.block-button {
    width: 130px;
    height: 30px;
    border-radius: 2px;
}

@-webkit-keyframes Gradient {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

@-moz-keyframes Gradient {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

@keyframes Gradient {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}