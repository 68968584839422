/* Header Descktop CSS start here */
.main_navbar_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    height: 56px;
    align-content: center;
    padding: 0px 10px;

}

.main_navbar_header_logo {
    margin: 0px 10px;
}

.main_navbar_header_logo_img {
    width: 90px;
    height: 30px;
}

.main_navbar_header_item {
    margin: 0px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.main_navbar_header_item_child {
    margin: 0px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.main_navbar_header_custom_links {
    margin-top: auto;
}

.main_navbar_header_item_link {
    color: #000000;
    text-decoration: none;
    font-size: 0.8rem;
    font-weight: bold;
    padding: 0.2rem 0.2rem;
    margin: 0.2rem;
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-radius: 0.5rem;
    transition: all 0.2s ease-in-out;
}

.main_navbar_header_search {
    margin: 0px 10px;
}

.main_navbar_header_search_icon {
    width: 20px;
    height: 20px;
}

.main_navbar_header_search_bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid black;
    border-radius: 30px;
}

.main_navbar_header_search_div {
    padding: 0.2rem 0.2rem;
    margin: 0px 0.2rem;
}

.main_navbar_header_search_input {
    width: 400px;
    height: 30px;
    outline: none;
    border: none;
    text-align: start;
    font-size: 0.9rem;
    font-weight: bold;
    color: #000000;
}

.main_navbar_header_search_input:focus {
    outline: none;
}


.main_navbar_header_auth_buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: #ffffff;
}

.main_navbar_header_item_auth_button {
    border: 1px solid #000000;
    padding: 0.2rem 0.2rem;
    border-radius: 0.5rem;
    transition: all 0.2s ease-in-out;
    width: 70px;
    height: 30px;
}

.main_navbar_header_item_auth_button:hover {
    background-color: rgb(0, 0, 0);
    color: #ffffff;
}

/* Header Desktop CSS end here */


/* Main navabr Categories Desktop CSS start here  */
.main_navbar_header_categories {
    position: absolute;
    top: 115px;
    left: 700px;
    transition: all 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #ffffff;
    width: 200px;
    z-index: 100;
    border-radius: 10px;
    height: 0px;
    overflow: hidden;
}

.main_navbar_category:hover .main_navbar_header_categories {
    height: auto;
    padding-top: 10px;
    border: 1px solid black;
}

.main_navbar_header_categories li {
    list-style: none;
    text-align: start;
    height: 30px;
    margin-left: -10px;
}

.main_navbar_header_categories_ul_link {
    color: #000000;
    text-decoration: none;
    font-size: 0.8rem;
    font-weight: bold;
}






/* main navbar profile button Desktop css starts here */

.main_navbar_profile_img_button {
    position: relative;
    right: 0;
    top: 0;
    width: 33px;
    height: 33px;
    margin: 2px;
    margin-left: 5px;
    background-color: #fff;
    border: 2px solid rgb(112, 82, 245);
    border-radius: 50%;
    border: 1px solid #e5e5e5;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}


.main_navbar_profile_img_button:hover {
    border: 1.2px solid black;
}

.main_navbar_profile_img {
    width: 2.0rem;
    height: 2.0rem;
    border-radius: 50%;
    border: 1px solid #000000;
    transform: scale(1.3);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.main_navbar_profile_img:hover {
    border: 2px solid black;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.main_navbar_user_profile_content {
    position: absolute;
    top: 130px;
    right: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    width: 200px;
    z-index: 103;
}

.main_navbar_user_profile_content_show {
    display: none;
}

.main_navbar_user_profile_content button {
    width: 170px !important;
}

.main_navbar_user_profile_name {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    border: 1px solid #f3b0b0;
    padding: 5px;
    border-radius: 5px;
}

.main_navbar_user_profile_content_item {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
}

.main_navbar_user_profile_content_item:hover {
    color: #00bcd4;
}

/* main navbar profile button css end here */


/* service and headers toggle buttons Desktop css start here */
.main_navbar_service_and_header_toggle_buttons {
    display: none;
}


/* mobile media query for mobile use */
@media screen and (max-width: 992px) {

    /* Header Mobile CSS start here */
    .main_navbar_header {
        flex-direction: column;
        margin-top: 10px;
        height: 340px;
        margin-bottom: 5px;
    }

    .main_navbar_header_logo_img {
        width: 50px;
        height: 30px;
    }

    .main_navbar_header_search_input {
        width: 200px;
        height: 30px;
    }

    .main_navbar_header_custom_links {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: auto;
        z-index: 102;
    }

    /* Main navabr Categories Desktop CSS start here  */
    .main_navbar_header_custom_links {
        position: absolute;
        top: 100px;
        top: -300px;
        background-color: rgb(11 142 150);
        width: 100%;
    }

    .main_navbar_header_custom_links * {
        color: #ffffff;
    }

    .main_navbar_header_auth_buttons_and_profile {
        position: fixed;
        bottom: 10px;
        right: 0;
        z-index: 100;
    }

    .main_navbar_header_auth_buttons {
        border-radius: 10px;
        padding-bottom: 2px;
        display: flex;
        flex-direction: column;
        background: #e2fac4;
        color: #ffffff;
    }

    .main_navbar_header_categories {
        position: absolute;
        top: 35px;
        left: 110px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: #ffffff;
        width: 200px;
        z-index: 100;
        border-radius: 10px;
        height: 0px;
        overflow: hidden;
    }

    .main_navbar_header_categories * {
        color: #000000;
    }

    .main_navbar_category:hover .main_navbar_header_categories {
        height: auto;
        padding-top: 10px;
        border: 1px solid black;
    }



    /* main navbar profile button Mobile css starts here */
    .main_navbar_user_profile_content {
        position: absolute;
        top: -260px;
        right: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        background-color: #f5f5f5;
        border: 1px solid black;
        border-radius: 5px;
        padding: 10px;
        width: 200px;
    }


    /* service and headers toggle buttons Mobile css start here */
    .main_navbar_service_and_header_toggle_buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: black;
        position: relative;
        top: -300px;
        padding: 5px;
        background-color: #5FBD6F;
    }

    .main_navbar_service_toggle_button,
    .main_navbar_header_toggle_button {
        border: 1px solid #000000;
        padding: 0.2rem 0.2rem;
        border-radius: 0.5rem;
        transition: all 0.1s ease-in-out;
        width: 70px;
        height: 35px;
    }


    .main_navbar_service_toggle_button:hover {
        background-color: rgb(0, 0, 0);
        color: #ffffff;
        cursor: pointer;
    }

    .main_navbar_header_toggle_button:hover {
        background-color: rgb(0, 0, 0);
        color: #ffffff;
        cursor: pointer;
    }

    /* service and headers toggle buttons Mobile css end here */

}





/* .mainNavbar__search_input {
    height: 31px;
    margin-top: 4px;
}

.main_navbar_profile_img_button {
    position: relative;
    right: 0;
    top: 0;
    width: 33px;
    height: 33px;
    margin: 2px;
    margin-left: 5px;
    background-color: #fff;
    border: 2px solid rgb(112, 82, 245);
    border-radius: 50%;
    border: 1px solid #e5e5e5;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}


.main_navbar_profile_img_button:hover {
    border: 1.2px solid rgb(7, 255, 7);
    transform: scale(1.1);
}

.main_navbar_user_profile_content {
    position: absolute;
    top: 57px;
    right: 17px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    width: 200px;
}

.main_navbar_user_profile_content_show {
    display: none;
}

.main_navbar_user_profile_content button {
    width: 170px !important;
}

.main_navbar_user_profile_name {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    border: 1px solid #f3b0b0;
    padding: 5px;
    border-radius: 5px;
}

.main_navbar_user_profile_content_item {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
}

.main_navbar_user_profile_content_item:hover {
    color: #00bcd4;
}

@media (max-width: 992px) {
    .main_navbar_mobile_view {
        display: block;
    }

    .main_navbar_desktop_view {
        display: none;
    }
}

@media (min-width: 992px) {
    .main_navbar_mobile_view {
        display: none;
    }

    .main_navbar_desktop_view {
        display: block;
    }
} */