.courseDescription img{
    max-width: 400px;
    max-height: 400px;
}



.course_details_top_icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 100px;
}

.course_details_top_icons_div {
    width: 200px;
}


@media screen and (max-width: 800px) {
    .courseDescription {
        width: 100% !important;
        overflow-y: auto;
    }

    .course_details_top_icons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 20px;
    }

    .course_details_top_icons_div {
        width: 300px;
    }

}