.home__main{
    margin-top: 80px !important;
}

.content_divider{
    min-height: 100vh;
}


@media (max-width: 992px) {
    .content_divider {
        margin-top: -300px;
    }
    .nav_divider{
        display: none;
    }
}