.transaction_details__top_info{
    background: #1c1d1f;
    color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
}

.transaction_details__text{
    flex: 0.6;
    text-align: start;
    margin: 20px;
    padding: 20px;
}

.transaction_details__thumbnail{
    flex: 0.3;
}

.transaction_details__thumbnail > img {
    width: 250px;
    height: 250px;
    border-radius: 10px;
}


@media screen and (max-width: 800px) {
    .transaction_details__top_info{
        flex-direction: column-reverse;
    }
    .transaction_details__thumbnail > img{
        width: 80%;
    }
}