.all_institutes_main_div {
    margin: 10px !important;
    padding: 20px !important;
}

.all_institutes_top_div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

@media (max-width: 990px) {
    .all_institutes_top_div {
        flex-direction: column;
        margin-bottom: 10px;
    }
}