.UploadedVideos__component{
    display: flex !important;
    flex-direction: row !important;
    /* flex-wrap: wrap !important; */
    justify-content: center !important;
    align-items: flex-start;
    flex: 0.5;
    max-height: 100vh;
    overflow: auto;
}

.UploadedVideos_divide_content{
    display: flex;
}

.UploadedVideos__video{
    flex: 0.5;
}


@media screen and (max-width: 800px) {
    .UploadedVideos_divide_content{
        flex-direction: column-reverse;
        height: 100vh;
        overflow: auto;
    }
}