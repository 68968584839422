.user_details__text {
    color: white;
    font-size: 14px;
    font-weight: bold;
    text-align: start;
    background:
        linear-gradient(red, transparent),
        linear-gradient(to top left, lime, transparent),
        linear-gradient(to top right, blue, transparent);
    background-blend-mode: screen;
    border-radius: 10px;
}

.user_details__text>div {
    margin-bottom: 8px;
}


@media screen and (max-width: 800px) {
    .user_details__text {
        font-size: 12px;
        text-align: center;
        margin-left: 15px !important;
    }
}