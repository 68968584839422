.assign_course_form_group{
    display: flex !important;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
}

.item {
    width: 35%;
    margin: 5px;
    text-align: start;
}


@media only screen and (max-width: 600px) {
    .assign_course_form_group{
        display: flex !important;
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
    }
    .item{
        width: 90%;
        margin: 5px;
    }
}