.users__main_div{
    margin: 10px !important;
    padding: 20px !important;
}

.users__top_div{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

@media (max-width: 990px) {
    .users__top_div{
        flex-direction: column;
        margin-bottom: 10px;
    }
}