.startCourseForm img{
    max-width: 400px !important;
    max-height: 400px !important;
}

/* .start__course_form_group {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: center;
}

.start__course__input {
    margin: 5px !important;
    width: 20rem;
}

.start__course__select {
    margin: 5px !important;
    width: 20rem !important;
    height: 40px;
}

.start__course__headings {
    font-size: 17px !important;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 6px;
    padding: 5px;
}


.start_course_form_text {
    text-align: start;
    padding: 0px 220px;
}

@media (max-width: 992px) {
    .start_course_form_text {
        padding: 0px;
    }
} */