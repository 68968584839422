.carousel_image {
    width: 100%;
    height: 30rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

@media (max-width: 992px) {
    .carousel_image {
        height: 20rem;
    }
}