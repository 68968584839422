/* Services CSS */
.main_navbar_services {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(11 142 150);
}

.main_navbar_service_link {
    /* color: #000000; */
    color: white;
    text-decoration: none;
    font-size: 0.8rem;
    font-weight: bold;
    padding: 0.2rem 0.2rem;
    margin: 0.2rem;
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-radius: 0.5rem;
    transition: all 0.2s ease-in-out;
}

.main_navbar_service_link:hover {
    color: #ffcc00;
    /* color: black; */
}

.main_navbar_service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;
}

@media (max-width: 992px) {
    .main_navbar_services {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -500px;
        background-color: rgb(11 142 150);
        z-index: 102;
    }

    .main_navbar_services * {
        color: #ffffff;
    }

    .main_navbar_service {
        width: 150px;
        height: 50px;
        margin: 5px;
    }

    .main_navbar_service_link:hover {
        background: #ffcc00;
        width: 90px;
        height: 60px;

    }
    
}