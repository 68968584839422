.GeneralManagement_tab .nav {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    flex-wrap: nowrap !important;
    padding: 5px !important;
    width: 100% !important;
    overflow-x: auto !important;
}

/* .GeneralManagement_tab .nav-item {
    min-width: 200px !important;
} */